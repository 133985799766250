/* eslint-disable no-param-reassign */
/**
 * 长按指令
 *
 * 遗留问题：长按的时候会触发系统的选择器。由于帖子部分需要能够复制，所以不能全局使用user-select:none;(对部分元素使用这个属性，长按时仍然会触发选择)；
 * 使用 event.preventDefault 可以阻止触发系统自带选择器，但是会造成无法触发滚动
 */


function clearPreventSelect() {
  document.body.style.userSelect = 'auto';
  document.body.style['-webkit-user-select'] = 'auto';
}

function preventSelect() {
  document.body.style.userSelect = 'none';
  document.body.style['-webkit-user-select'] = 'none';
  // 禁用选择后，1s中自动恢复
  setTimeout(() => {
    clearPreventSelect();
  }, 1000);
}

const directiveOption = {
  bind(el, binding, vnode) {
    el.dataset.longPressTimeoutId = '0';
    const onpointerup = function () {
      clearTimeout(parseInt(el.dataset.longPressTimeoutId, 10));
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('long-press-stop');
      } else {
        const longPressStop = new CustomEvent('long-press-stop');
        el.dispatchEvent(longPressStop);
      }

      // 清除禁用选择
      if (binding.modifiers.prevent) {
        clearPreventSelect();
      }
      document.removeEventListener('touchend', onpointerup);
    };
    const onpointerdown = function (event) {
      // 如果preventDefault，会导致触摸这部分的滚动失效
      // if (binding.modifiers.prevent) {
      //   event.preventDefault();
      // }
      // 按下的时候禁用选择
      if (binding.modifiers.prevent) {
        preventSelect();
      }
      document.addEventListener('touchend', onpointerup);
      el.dataset.x = event.touches[0].pageX;
      el.dataset.y = event.touches[0].pageY;
      const timeout = setTimeout(() => {
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('long-press-start');
        } else {
          const longPressStart = new CustomEvent('long-press-start');
          el.dispatchEvent(longPressStart);
        }
      }, binding.value);
      el.dataset.longPressTimeoutId = timeout.toString();
    };
    const onPointerMove = function (event) {
      const moveX = event.touches[0].pageX - el.dataset.x;
      const moveY = event.touches[0].pageY - el.dataset.y;
      const deviation = 2;
      if (moveX > deviation || moveX < -deviation || moveY > deviation || moveY < -deviation) {
        clearTimeout(parseInt(el.dataset.longPressTimeoutId, 10));
      }
    };
    el.$_long_press_pointerdown_handler = onpointerdown;
    el.addEventListener('touchstart', onpointerdown);
    el.addEventListener('touchmove', onPointerMove);
  },
  unbind(el) {
    clearTimeout(parseInt(el.dataset.longPressTimeoutId, 10));
    el.removeEventListener('touchstart', el.$_long_press_pointerdown_handler);
  },
};

export default directiveOption;
