<template>
  <div
    class="com-post-comment"
  >
    <div
      class="gap"
    />
    <div
      v-anchor="useAnchor"
      class="anchor-point"
    >
    <!-- 锚点位置，防止被title bar遮住 -->
    </div>
    <ScrollView
      :loading="commentLoading"
      :error="commentError"
      :no-more="commentEnd || !isApp"
      :bottom-distance="bottomDistance"
      :no-more-text="isApp && newCommentList.length?'没有更多内容了':''"
      @reachBottom="onReachBottomHandler"
      @bottomErrorClick="onBottomErrorClickHandler"
      class="com-post-comment-scroll"
    >
      <div class="com-post-comment-title">
        <div
          class="com-post-comment-title-main"
        >
          评论 {{ commentTotalNumber > 0 ? commentTotalNumber : '' }}
        </div>
        <div
          v-if="commentTotalNumber > 0 && isApp"
          @click="showSortTypeSelect"
          class="com-post-comment-title-filter"
        >
          {{ commentSortTypeCurrent['text'] }}
          <div class="icon-sort" />
        </div>
        <div
          v-else-if="commentTotalNumber > 0 && !isApp"
          class="com-post-comment-title-filter"
        >
          <span>最热</span>
        </div>
        <transition
          name="fade"
          enter-class="fade-in-enter"
          enter-active-class="fade-in-active"
          leave-class="fade-out-enter"
          leave-active-class="fade-out-active"
        >
          <div
            v-show="commentSortShow"
            class="com-post-comment-title-popup"
          >
            <div class="com-post-comment-title-popup-container">
              <a
                v-for="(item, index) in commentSortTypeList"
                :key="index"
                :class="{active: commentSortTypeCurrent['value'] === item['value']}"
                @click="changeSortType(item)"
                href="javascript:;"
                class="com-post-comment-title-popup-container-item"
              >
                <div class="text">
                  {{ item['text'] }}评论
                </div>
                <div class="icon-check" />
              </a>
            </div>
          </div>
        </transition>
        <div
          v-show="commentSortShow"
          @touchstart="onShadowTouchHandler"
          class="com-post-comment-title-shadow"
        />
      </div>
      <div
        v-if="!commentLoading && !commentError && newCommentList.length === 0"
        class="com-post-comment__empty"
      >
        <div class="com-post-comment__empty-img" />
        <div class="com-post-comment__empty-text">
          暂时没有有缘人发表评论，快来暖暖贴
        </div>
      </div>
      <div
        v-else
        class="com-post-comment-list"
      >
        <transition-group name="comment-item">
          <div
            v-for="(item, index) in newCommentList"
            :key="item.id"
            :class="{'no-animation': commentItemNoAnimation}"
            class="com-post-comment-item"
          >
            <CommentItem
              :comment-data="item"
              :comment-fold="commentFold"
              :creator-info="authorInfo"
              :index="index"
              :show-online-status="showOnlineStatus"
              :group-info="groupInfo"
              :sub-comment-page-context="subCommentPageContext[index]"
              @changeSubCommentOperate="changeSubCommentOperateHandler($event)"
              @postSubComment="onPostSubCommentHandler($event, index)"
              @postComment="onPostCommentHandler($event, index)"
              @updateCommentItem="updateCommentItem"
              @subCommentLoadMore="onSubCommentLoadMore(index)"
              @deleteComment="onDeleteComment"
              @deleteSubComment="onDeleteSubComment"
              @updateSubCommentItem="updateSubCommentItem"
              @accuseComment="onAccuseComment"
              @sendNotice="sendNotice"
            />
          </div>
        </transition-group>
        <div
          v-if="!isApp && newCommentList.length"
          @click="onClickWriteHandler"
          class="com-post-comment-write"
        >
          <img
            src="./img/icon-write.png"
            class="com-post-comment-write-icon"
            alt="写评论"
          >
          <span>写评论</span>
        </div>
      </div>
    </ScrollView>
    <CommentAccuse
      v-model="commentAccuseStatus"
      :comment-data="commentAccuseDetail"
      class="post-comment-accuse"
    />
    <DialogComponent
      v-model="deleteCommentDialogStatus"
      :confirm="true"
      @confirm="onDeleteCommentConfirm"
    >
      <div class="content">
        是否确认删除
      </div>
    </DialogComponent>
    <!--  <CommentFeedback-->
    <!--    :comment-data="feedbackCommentData"-->
    <!--    :popup-position="feedbackPopupPosition"-->
    <!--    :popup-style="feedbackPopupStyle"-->
    <!--    :show-online-status="showOnlineStatus"-->
    <!--    @closeFeedbackPopup="onCloseFeedbackPopupHandler"-->
    <!--    @deleteCommentItem="onDeleteCommentItemHandler"-->
    <!--  />-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CommentAccuse from '@/component/comment-accuse/index.vue';
import CommentItem from '@/component/comment-item/index.vue';
import DialogComponent from '@/ui/dialog/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import commentApi from '@/api/comment';
import manage from '@/api/manage';
import { postComment, sendGlobalNotification } from '@/jsbridge';
import { reportEnum } from '@/api/report';
import { logger } from '@/util/logger';
import launchApp from '@/util/launchApp';

const uniqueArr = (arr) => {
  const tempIdArr = [];
  const tempArr = [];
  arr.forEach((item) => {
    if (tempIdArr.indexOf(item.id) === -1) {
      tempIdArr.push(item.id);
      tempArr.push(item);
    }
  });
  return tempArr;
};

const commentSortTypeList = [
  {
    text: '最热',
    value: 2,
  },
  {
    text: '最新',
    value: 3,
  },
];

export default {
  name: 'ComPostComment',
  components: {
    CommentItem,
    ScrollView,
    CommentAccuse,
    DialogComponent,
  },
  inject: ['reportData'],
  props: {
    postMain: {
      type: String,
      default: '',
    },
    postId: {
      type: [String, Number],
      default: 1,
    },
    commentTotalNumber: {
      type: Number,
      default: 0,
    },
    bottomDistance: {
      type: Number,
      default: 0,
    },
    authorInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    anchorWatcher: {
      type: Array,
      default: () => [],
    },
    groupInfo: {
      type: Object,
      default: () => ({}),
    },
    reportContentType: {
      type: String,
      default: '',
    },
    showOnlineStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentDetailShow: false,
      commentSortShow: false,
      commentSortTypeList,
      commentSortTypeCurrent: commentSortTypeList[0],
      commentFold: 3,
      commentPageSize: 10,
      commentPageContext: null,
      commentList: [],
      newCommentList: [],
      commentLoading: true,
      commentLoaded: false,
      commentError: false,
      commentEnd: false,
      // 操作
      feedbackDialogStatus: false,
      commentItemNoAnimation: true,
      anchorPoint: null,
      subCommentPageContext: [],
      // 评论举报
      commentAccuseStatus: false,
      commentAccuseDetail: null,
      // 删除评论弹窗
      deleteCommentDialogStatus: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
    }),
    useAnchor() {
      const anchorWatcher = this.anchorWatcher.every(item => !!item);
      return anchorWatcher && this.anchorPoint === 'comment' && this.commentLoaded && this.postMain !== 'video';
    },
  },
  watch: {
    postId(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.initCommentList();
      }
    },
    commentList(newVal) {
      this.newCommentList = this.isApp ? newVal : newVal.slice(0, 3);
    },
  },
  mounted() {
    this.resetData();
    this.anchorPoint = this.$route.query.anchorPoint || '';
    if (this.postId) {
      this.initCommentList().then(() => {
        if (this.reportData) {
          this.reportData({
            moduleid: 1,
            eventtype: 2,
            extendid: this.reportContentType === reportEnum.contenttype.POST_VIDEO ? 3 : 1, // 视频页单独处理
          }, '详情页评论模块曝光');
        }
      });
    }
  },
  beforeDestroy() {
    this.resetData();
  },
  methods: {
    /**
     * 显示切换排列顺序
     * */
    showSortTypeSelect() {
      if (this.showTouchLock) return;
      if (this.commentSortShow) {
        this.commentSortShow = false;
      } else {
        this.commentSortShow = true;
        if (this.reportData) {
          this.reportData({
            moduleid: 37,
            eventtype: 3,
            extendid: 1,
          }, '排序按钮点击');
        }
      }
    },
    /**
     * 隐藏切换排列顺序
     * */
    hideSortTypeSelect() {
      this.commentSortShow = false;
    },
    /**
     * 失焦关闭
     * */
    onShadowTouchHandler() {
      if (this.showTouchLock) return;
      this.hideSortTypeSelect();
      this.showTouchLock = true;
      setTimeout(() => {
        this.showTouchLock = false;
      }, 300);
    },
    /**
     * 切换排列顺序
     * */
    changeSortType(item) {
      this.hideSortTypeSelect();
      this.commentSortTypeCurrent = item;
      this.commentItemNoAnimation = true;
      this.$nextTick(() => {
        this.initCommentList();
      });
      if (this.reportData) {
        if (item.value === 2) {
          this.reportData({
            moduleid: 37,
            eventtype: 3,
            extendid: 3,
          }, '评论模块最热评论点击');
        } else if (item.value === 3) {
          this.reportData({
            moduleid: 37,
            eventtype: 3,
            extendid: 2,
          }, '评论模块最新评论点击');
        }
      }
    },
    resetData() {
      this.commentLoading = true;
      this.commentError = false;
      this.commentEnd = false;
      this.commentPageContext = null;
      this.commentList = [];
    },
    /**
     * 初始化评论列表
     * */
    initCommentList() {
      this.commentLoading = true;
      this.commentError = false;
      this.commentEnd = false;
      this.commentPageContext = null;
      this.commentList = [];
      return this.getCommentList();
    },
    /**
     * 后台请求列表
     * */
    getCommentList() {
      this.commentLoading = true;
      const data = {
        id: this.postId,
        scenery: 2,
        type: 2,
        orderBy: 3,
        sortType: this.commentSortTypeCurrent.value,
        pageSize: this.commentPageSize,
        pageContext: this.commentPageContext,
        sonNumber: this.commentFold,
      };
      if (this.userInfo) data.userId = this.userInfo.uid;
      return commentApi.getCommentListByPostId(data)
        .then((rsp) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.commentLoading = false;
          this.commentLoaded = true;
          const list = uniqueArr(rsp.list);
          if (this.commentList.length === 0) {
            this.commentList = list;
          } else {
            const addList = list.filter(item => !this.commentList.some(item2 => item2.id === item.id));
            this.commentList = this.commentList.concat(addList);
          }
          this.commentPageContext = rsp.pageContext;
          this.commentEnd = rsp.list.length < this.commentPageSize;
          this.commentSortShow = false;
          return rsp;
        })
        .catch((err) => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.commentLoading = false;
          this.commentError = true;
          return Promise.reject(err);
        });
    },
    /**
     * 后台请求列表
     * */
    onReachBottomHandler() {
      if (this.commentLoading || this.commentEnd || this.commentError) return;
      if (this.reportData) {
        this.reportData({
          moduleid: 1,
          eventtype: 8,
          extendid: 1,
        }, '详情页上拉加载');
      }
      this.getCommentList();
    },
    /**
     * 底部失败点击回调
     * */
    onBottomErrorClickHandler() {
      this.commentError = false;
      this.getCommentList();
    },
    /**
     * 二级评论无限展开子评论
     * */
    onSubCommentLoadMore(index) {
      if (this.reportData) {
        if (this.subCommentPageContext[index]) {
          // 1501000150304
          this.reportData({
            pageid: 1501,
            moduleid: 15, // 回复
            eventtype: 3,
            extendid: 4,
            indexid: index + 1,
          }, '所有内容详情页二级评论“展开x条回复”点击');
        } else {
          // 1501000150303
          this.reportData({
            pageid: 1501,
            moduleid: 15, // 回复
            eventtype: 3,
            extendid: 3,
            indexid: index + 1,
          }, '所有内容详情页二级评论“共x条回复”点击');
        }
      }
      if (this.commentList[index].subCommentLoading) return;
      const params = {
        parentId: this.commentList[index].id,
        rootId: this.commentList[index].rootId,
        scenery: 2,
        type: 3,
        orderBy: 4,
        pageSize: this.commentPageSize,
        pageContext: this.subCommentPageContext[index] || null,
        sonNumber: 0,
        skipNum: this.commentList[index].subCommentList.length,
        parentPageContext: this.commentPageContext || null,
      };
      this.$set(this.commentList[index], 'subCommentLoading', true);
      this.$set(this.commentList[index], 'subCommentError', false);
      if (this.userInfo) params.userId = this.userInfo.uid;
      commentApi.getSubCommentListByPostId(params)
        // eslint-disable-next-line consistent-return
        .then((rsp) => {
          // throw '';
          if (this.$store.state.app.isInBackground) return Promise.stop();
          const list = uniqueArr(rsp.data);
          if (this.commentList[index].subCommentList.length === 0) {
            this.commentList[index].subCommentList = list;
          } else {
            // eslint-disable-next-line max-len
            const addList = list.filter(item => !this.commentList[index].subCommentList.some(item2 => item2.id === item.id));
            this.commentList = this.commentList.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  subCommentList: item.subCommentList.concat(addList),
                  subCommentEnd: rsp.pageIsEnd || item.subCommentAmount === item.subCommentList.length + addList.length,
                };
              }
              return {
                ...item,
              };
            });
          }
          this.subCommentPageContext[index] = rsp.pageContext;
        })
        // eslint-disable-next-line consistent-return
        .catch(() => {
          if (this.$store.state.app.isInBackground) return Promise.stop();
          this.$set(this.commentList[index], 'subCommentError', true);
        })
        .finally(() => {
          this.$set(this.commentList[index], 'subCommentLoading', false);
        });
    },
    /**
     * 禁止滚动
     * */
    disabledTouchMove(e) {
      e.preventDefault();
    },
    /**
     * 举报
     * */
    onAccuseComment(commentData) {
      this.commentAccuseDetail = commentData;
      this.commentAccuseStatus = true;
    },
    onCloseFeedbackPopupHandler() {
      // setTimeout
      // userInfo && (commentData.userId === userInfo.uid) && operateFeedback
      setTimeout(() => {
        this.commentItemNoAnimation = true;
      }, 500);
    },
    onDeleteCommentItemHandler({ parentId, commentId } = {}) {
      this.deleteCommentItem({
        parentId,
        commentId,
      });
    },
    onPostSubCommentHandler(toCommentData, index) {
      // console.log(index);
      let attribute;
      // accountType:0 为普通用户，1 官方一级 2 官方二级 3 官方个人 4 媒体官方 5 媒体个人
      if (Number(this.userInfo.accountType) > 0 && Number(this.userInfo.accountType) < 3) {
        attribute = 3;
      } else if (Number(this.userInfo.uid) === Number(this.authorInfo.uid)) {
        attribute = 2;
      } else {
        attribute = 1;
      }
      // console.log(toCommentData.nickname);
      postComment({
        type: 3,
        parentId: toCommentData.parentId,
        rootId: this.postId,
        commentatorId: toCommentData.userId,
        attribute,
        content: toCommentData.content,
        nickname: toCommentData.nickname,
        replyId: toCommentData.id,
        groupId: toCommentData.groupId,
      })
        .then((commentData) => {
          logger.info('postCommentData', commentData);
          // 终端弹窗
          this.addSubCommentItem({
            parentId: toCommentData.parentId,
            data: {
              commentData,
            },
          });
          if (this.reportData) {
            this.reportData({
              moduleid: 16, // 回复
              eventtype: 6,
              extendid: 1,
              indexid: index + 1,
            }, '详情页回复成功');
          }
        });
    },
    /**
     * 改变评论操作显示隐藏
     * */
    changeSubCommentOperateHandler({ subIndex, index, type }) { // type:  show、hide(全屏点击的时候隐藏)
      this.$set(this.commentList[index].subCommentList[subIndex], 'operatePopupStatus', type === 'show');
    },
    onPostCommentHandler(toCommentData, index) {
      let attribute;
      // accountType:0 为普通用户，1 官方一级 2 官方二级 3 官方个人 4 媒体官方 5 媒体个人
      if (Number(this.userInfo.accountType) > 0 && Number(this.userInfo.accountType) < 3) {
        attribute = 3;
      } else if (Number(this.userInfo.uid) === Number(this.authorInfo.uid)) {
        attribute = 2;
      } else {
        attribute = 1;
      }
      postComment({
        type: 3,
        parentId: toCommentData.id,
        rootId: this.postId,
        commentatorId: toCommentData.userId,
        attribute,
        content: toCommentData.content,
        nickname: toCommentData.nickname,
        groupId: toCommentData.groupId,
      })
        .then((commentData) => {
          logger.info('postCommentData', commentData);
          // 终端弹窗
          this.addSubCommentItem({
            parentId: toCommentData.id,
            data: {
              commentData,
            },
          });
          if (this.reportData) {
            this.reportData({
              moduleid: 16, // 回复
              eventtype: 6,
              extendid: 1,
              indexid: index + 1,
            }, '详情页回复成功');
          }
        });
    },
    // 删除一级评论
    onDeleteComment({ index, commentData, hasDeletePerm }) {
      this.deleteCommentDetail = { index, commentData, hasDeletePerm };
      this.deleteCommentDialogStatus = true;
    },
    // 删除二级评论
    onDeleteSubComment({
      index, subIndex, commentData, hasDeletePerm,
    }) {
      this.deleteCommentDetail = {
        index, subIndex, commentData, hasDeletePerm,
      };
      this.deleteCommentDialogStatus = true;
    },

    onDeleteCommentConfirm() {
      const { commentData, hasDeletePerm } = this.deleteCommentDetail;
      if (!commentData) return;
      if (this.deleteCommentByIdLock) return;
      this.deleteCommentByIdLock = true;
      if ((Number(this.userInfo.uid) !== Number(commentData.userId)) && hasDeletePerm) {
        manage.MobileDeleteComment({
          commentId: commentData.id,
          updateBitSet: 4,
        })
          .then(() => {
            this.$toast('删除成功');
            if (this.deleteCommentDetail.subIndex >= 0) {
              this.deleteSubCommentData({
                index: this.deleteCommentDetail.index,
                subIndex: this.deleteCommentDetail.subIndex,
              });
            } else {
              this.deleteCommentItem({
                commentId: commentData.id,
              });
            }
            this.sendNotice({
              operate: 'delete',
              commentData,
            });
          })
          .catch(() => {
            this.$toast('删除失败');
          })
          .finally(() => {
            this.deleteCommentByIdLock = false;
          });
      } else {
        commentApi.deleteCommentById({
          commentId: commentData.id,
          updateBitSet: 4,
          data: {
            userId: commentData.userId,
            rootId: commentData.rootId,
            parentId: commentData.parentId,
            type: commentData.type,
          },
        })
          .then(() => {
            this.$toast('删除成功');
            if (this.deleteCommentDetail.subIndex >= 0) {
              this.deleteSubCommentData({
                index: this.deleteCommentDetail.index,
                subIndex: this.deleteCommentDetail.subIndex,
              });
            } else {
              this.deleteCommentItem({
                commentId: commentData.id,
              });
            }
            this.sendNotice({
              operate: 'delete',
              commentData,
            });
          })
          .catch(() => {
            this.$toast('删除失败');
          })
          .finally(() => {
            this.deleteCommentByIdLock = false;
          });
      }
    },
    /**
     * 增加一级评论
     * @param { Object } params 评论信息
     * @param { Object } params.commentData 评论信息
     * */
    addCommentItem({ commentData } = {}) {
      this.commentList = [
        commentData,
        ...this.commentList.slice(),
      ];
      this.$emit('updateCommentAmount', {
        num: 1,
      });
    },
    /**
     * 删除一级评论
     * @param { Object } params 评论信息
     * @param { Object } params.commentId 评论id
     * */
    deleteCommentItem({ commentId } = {}) {
      function removeItem(array, index) {
        return [
          ...array.slice(0, index),
          ...array.slice(index + 1),
        ];
      }
      // 遵循Immutable
      for (let i = 0; i < this.commentList.length; i++) {
        const item = this.commentList[i];
        if (item.id === commentId) {
          this.commentList = removeItem(this.commentList, i);
          this.$emit('updateCommentAmount', {
            num: item.subCommentAmount * -1 - 1,
          });
          return true;
        }
        // 一级评论不需要下面的逻辑
        // if (item.id === parentId) {
        //   for (let j = 0; j < item.subCommentList.length; j++) {
        //     const subItem = item.subCommentList[j];
        //     if (subItem.id === commentId) {
        //       const subArray = removeItem(item.subCommentList, j);
        //       this.commentList = [
        //         ...this.commentList.slice(0, i),
        //         {
        //           ...item,
        //           subCommentList: subArray,
        //         },
        //         ...this.commentList.slice(i + 1),
        //       ];
        //       return true;
        //     }
        //   }
        // }
      }
      return true;
    },
    /**
     * 更新一级评论
     * @param { Object } params 评论信息
     // * @param { Object } params.parentId 父级评论id
     * @param { Object } params.commentId 评论id
     * */
    updateCommentItem({/* parentId, */ commentId, data = {} } = {}) {
      // 如果是当前打开的评论详情 那么要同步更新
      this.commentList = this.commentList.map((item) => {
        if (item.id === commentId) {
          return {
            ...item,
            ...data,
          };
        }
        // 一级评论不需要判断二级
        // if (item.id === parentId) {
        //   return {
        //     ...item,
        //     subCommentList: item.subCommentList.map((item1) => {
        //       if (item1.id === commentId) {
        //         return {
        //           ...item1,
        //           ...data,
        //         };
        //       }
        //       return item1;
        //     }),
        //   };
        // }
        return item;
      });
    },
    /**
     * 更新评论列表
     * @param { Object } params 评论信息
     * @param { Object } params.parentId 父级评论id
     * @param { Object } params.commentId 评论id
     * */
    updateCommentList({
      parentId, commentId, data = {},
    } = {}) {
      // 遵循Immutable
      this.commentList = this.commentList.map((item) => {
        if (item.id === commentId) {
          return {
            ...item,
            ...data,
          };
        }
        if (item.id === parentId) {
          return {
            ...item,
            subCommentList: item.subCommentList.map((item1) => {
              if (item1.id === commentId) {
                return {
                  ...item1,
                  ...data,
                };
              }
              return item1;
            }),
          };
        }
        return item;
      });
    },
    /**
     * 增加二级评论
     * @param { Object } params 评论信息
     * @param { Object } params.parentId 二级评论的一级评论id
     * @param { Object } params.data {commentData = {}} 详细的评论数据
     * */
    addSubCommentItem({ parentId, data = {} } = {}) {
      this.commentList = this.commentList.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            subCommentList: [
              ...item.subCommentList.slice(),
              data.commentData,
            ],
            subCommentAmount: Number(item.subCommentAmount) + 1,
          };
        }
        return item;
      });
      this.$emit('updateCommentAmount', {
        num: 1,
      });
    },
    /**
     * 删除二级评论
     * @param { Object } params 评论信息
     * */
    deleteSubCommentData({ index, subIndex } = {}) {
      if (this.commentList[index] && this.commentList[index].subCommentList[subIndex]) {
        const newArr1 = this.commentList[index].subCommentList.slice(0, subIndex);
        const newArr2 = this.commentList[index].subCommentList.slice(subIndex + 1);
        const subCommentList = [].concat(newArr1, newArr2);
        // console.log('subCommentList', subCommentList);
        this.$set(this.commentList[index], 'subCommentList', subCommentList);
        this.$set(this.commentList[index], 'subCommentAmount', this.commentList[index].subCommentAmount - 1);
      }
      this.$emit('updateCommentAmount', {
        num: -1,
      });
      // function removeItem (array, index) {
      //   return [
      //     ...array.slice(0, index),
      //     ...array.slice(index + 1),
      //   ];
      // }
      // console.log('call deleteSubCommentItem', parentId, commentId, this.commentList);
      // for (let i = 0; i < this.commentList.length; i++) {
      //   const item = this.commentList[i];
      //   if (item.id === parentId) {
      //     console.log('beforeDelete', item);
      //     for (let j = 0; j < item.subCommentList.length; j++) {
      //       const subItem = item.subCommentList[j];
      //       let subArray = item.subCommentList;
      //       if (subItem.id === commentId) {
      //         console.log('remote subItem', subItem);
      //         subArray = removeItem(item.subCommentList, j);
      //         console.log('remote item', subArray, j);
      //         this.commentList = [
      //           ...this.commentList.slice(0, i),
      //           {
      //             ...item,
      //             subCommentList: subArray,
      //             subCommentAmount: Number(item.subCommentAmount) - 1,
      //           },
      //           ...this.commentList.slice(i + 1),
      //         ];
      //         return;
      //       }
      //     }
      //   }
      // }
    },
    /**
     * 更新二级评论
     * @param { Object } params 评论信息
     * @param { Object } params.parentId 一级评论id
     * @param { Object } params.commentId 二级评论id
     * @param { Object } params.data {commentData = {}} 详细的评论数据
     * */
    updateSubCommentItem({ parentId, commentId, data = {} } = {}) {
      // alert(`parentId: ${parentId}, commentId: ${commentId}, JSON: ${JSON.stringify(data)}`);
      this.commentList = this.commentList.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            subCommentList: item.subCommentList.map((item1) => {
              if (item1.id === commentId) {
                logger.info(`item1.id: ${item1.id}, ${typeof item1.id}, commentId： ${commentId}, ${typeof commentId}, ${JSON.stringify(data.data)}`);
                return {
                  ...item1,
                  ...data.data,
                };
              }
              return item1;
            }),
          };
        }
        return item;
      });
    },
    // 通知全局事件
    sendNotice({ operate, data, commentData }) {
      sendGlobalNotification('g_updateComment', {
        operate,
        root_id: commentData.rootId,
        parent_id: commentData.parentId,
        comment_id: commentData.id,
        data,
        type: commentData.type,
      });
    },

    onClickWriteHandler() {
      //  视频链接是终端链接 其他的帖子都是H5链接
      const { postMain, postId } = this;
      let scheme = `tgc://browser?url=${encodeURIComponent(`${window.location.href}&anchorPoint=comment&_gcwv=2`)}`;
      if (postMain === 'video') scheme = `tgc://video_room?feedId=${postId}&anchorPoint=comment`;
      launchApp({ scheme });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
