<template>
  <div
    v-if="accuseDialogStatus"
    class="com-comment-accuse"
  >
    <div
      @touchmove="disabledTouchMove"
      @click="closeFeedbackPopup"
      class="com-comment-accuse-shadow"
    />
    <div
      class="com-comment-accuse-main"
    >
      <div class="com-comment-accuse-main-title ui-border-b">
        选择举报理由
      </div>
      <div class="com-comment-accuse-main-reason">
        <ul class="com-comment-accuse-main-list">
          <li
            v-for="(item, index) in accuseReasonList"
            :key="index"
            @click="accuseCommentItem(item)"
            class="com-comment-accuse-main-list-item ui-border-b"
          >
            {{ item.desc }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import manage from '@/api/manage';
import commentApi from '@/api/comment';
import { checkEnv } from '@/util/browser-env';
import {
  sendGlobalNotification,
} from '@/jsbridge';

export default {
  name: 'ComCommentAccuse',
  components: {
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    commentData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 操作
      accuseDialogStatus: false,
      accuseReasonList: [],
      accuseCommentLoading: false, // 正在accuse
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.accuseDialogStatus = false;
        } else {
          this.showAccuseDialog();
        }
      },
    },
  },
  mounted() {
    // this.showAccuseDialog();
  },
  methods: {
    /**
     * 禁止滚动
     * */
    disabledTouchMove(e) {
      e.preventDefault();
    },
    /**
     * 关闭反馈弹窗
     * */
    closeFeedbackPopup() {
      this.$emit('change', false);
      this.$emit('closeFeedbackPopup');
    },
    getAccuseReasonList({ errorToast = false } = {}) {
      if (this.accuseReasonList.length > 0) {
        return Promise.resolve();
      }
      return manage.getPunishReasons(2).then((result) => {
        this.accuseReasonList = result?.punishReasonList || [];
      })
        .catch(() => {
          if (errorToast) this.$toast('获取举报原因失败，请重试');
          return Promise.reject(new Error(''));
        });
    },
    showAccuseDialog() {
      this.getAccuseReasonList({ errorToast: true })
        .then(() => {
          this.accuseDialogStatus = true;
        })
        .catch(() => {
          this.$emit('change', false);
        });
    },
    closeAccuseDialog() {
      this.accuseDialogStatus = false;
    },
    accuseCommentItem(reason) {
      if (this.accuseCommentLoading) return;
      this.accuseCommentLoading = true;
      // TODO: ios 审核需要
      if (checkEnv().isSwitchOn && (!this.userInfo || !this.userInfo.uid)) {
        this.$toast('举报成功，小编审核中');
        this.accuseCommentLoading = false;
        this.$emit('change', false);
        return;
      }
      commentApi.updateCommentById({
        commentId: this.commentData.id,
        updateBitSet: 256,
        data: {
          userId: this.userInfo.uid,
          rootId: this.commentData.rootId,
          parentId: this.commentData.parentId,
          type: this.commentData.type,
          status: 1,
          accuses: 1,
        },
        accuseReason: reason.desc,
      })
        .then(() => {
          this.$toast('举报成功，小编审核中');
          this.$emit('change', false);
        })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('举报失败，请重试');
          }
        })
        .finally(() => {
          this.accuseCommentLoading = false;
        });
    },
    // 通知全局事件
    sendNotice(operate, data = '') {
      sendGlobalNotification('g_updateComment', {
        operate,
        root_id: this.commentData.rootId,
        parent_id: this.commentData.parentId,
        comment_id: this.commentData.id,
        data,
        type: this.commentData.type,
      });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
