<template>
  <div
    :class="'size-' + size"
    class="ui-applaud"
  >
    <div class="ui-applaud__ani" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      validator: val => (['s', 'm'].indexOf(val) > -1),
      required: true,
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
