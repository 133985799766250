<template>
  <div class="com-comment-item">
    <Avatar
      :avatar="creatorUserInfo.iconUrl"
      :account-type="creatorUserInfo.accountType"
      :vip-type="creatorUserInfo.vipType"
      @click.native="onAvatarClickHandler();"
      class="com-comment-item-avatar"
      size="s"
    />
    <div
      :class="borderBottom ? 'ui-border-b' : ''"
      class="com-comment-item-info"
    >
      <div class="com-comment-item-info-main">
        <div class="user">
          <div class="text">
            <div class="text-inner">
              <span
                :class="{'ui-nowrap': type !=='detail-reply' || Number(commentData.replyId) === 0 }"
                @click="$checkAppDeco($linkToPersonal, commentData.userId)"
                class="username"
              >{{ commentData.nickname }}</span>
              <GroupOwner
                :group-owner-type="getGroupOwnerType(commentData.user, groupInfo)"
                class="tag-group-owner"
              />
              <span
                v-if="Number(commentData.attribute) === 2"
                class="tag-author"
              >
                <span class="tag-author-inner">作者</span>
              </span>
              <span
                v-if="Number(commentData.attribute) === 3"
                class="tag-official"
              />
              <span
                v-if="Number(commentData.attribute) === 4"
                class="tag-author"
              >
                <span class="tag-author-inner">置顶</span>
              </span>
              <LabelAuthorRank
                v-show="((Number(creatorUserInfo.accountType) > 0
                  && Number(creatorUserInfo.vipType) !== 1)
                  || Number(creatorUserInfo.accountType) <= 0)
                  && Number(creatorUserInfo.level) > 0
                  && (!creatorUserInfo.userBadge || !creatorUserInfo.userBadge.isShow)"
                :level="Number(creatorUserInfo.level)"
                class="label-author-rank"
              />
              <!-- 勋章 -->
              <badge-icon
                v-if="creatorUserInfo.userBadge && !!creatorUserInfo.userBadge.isShow"
                :user-info="creatorUserInfo"
                :report-is-comment="true"
                class="label-badge-icon"
              />
              <!-- 声望图标 -->
              <PrestigeIcon
                v-if="creatorUserInfo.renownInfo && creatorUserInfo.renownInfo.iconUrl"
                :icon-url="creatorUserInfo.renownInfo.iconUrl"
                class="label-prestige-icon"
              />
              <!-- 在线状态 -->
              <OnlineStatus
                v-if="showOnlineStatus"
                :status="creatorUserInfo.onlineStatus"
                :game-code="creatorUserInfo.extend && creatorUserInfo.extend.teamGameCode"
                class="label-online-status"
              />
              <span class="time">{{ commentData.createdAt | getRelativeTime }}</span>
              <span class="ip-region">{{ commentData.ipRegionStr || '未知' }}</span>
            </div>
          </div>
          <div
            :class="{active: commentData.hasLike}"
            @click="$checkAuthDeco(onLikeHandler);onLikeHandlerReport()"
            class="like"
          >
            <ApplaudAni
              v-if="showApplaudAniTime"
              size="s"
              class="icon-like"
            />
            <Icon
              v-else
              :active="!!(commentData.hasLike)"
              name="applaud"
              size="m"
            />
            <div class="text">
              {{ commentData.like | translateNumToText }}
            </div>
          </div>
        </div>
        <div
          class="comment-text-wrapper"
        >
          <div
            v-show="operatePopupStatus"
            class="tip"
          >
            <div class="tip-detail">
              <span
                @click.stop="onCommentReply()"
                class="tip-detail-item"
              >回复</span>
              <span
                v-if="commentData.content.trim()"
                @click.stop="onCopyComment()"
                class="tip-detail-item"
              >复制</span>
              <input
                ref="fakeCopyCommentDetailRef"
                :value="commentData.content"
                class="tip-detail-hidden"
                readonly
              >
              <span
                v-if="(userInfo && (Number(commentData.userId) === Number(userInfo.uid))) || hasDeletePerm"
                @click.stop="onCommentDelete()"
                class="tip-detail-item"
              >删除</span>
              <span
                v-if="!userInfo || (Number(commentData.userId) !== Number(userInfo.uid))"
                @click.stop="onCommentAccuse()"
                class="tip-detail-item"
              >举报</span>
            </div>
            <div class="tip-triangle" />
          </div>
          <div
            v-show="operatePopupStatus"
            @touchstart="hideOperateDialog()"
            class="shadow"
          />
          <div
            v-long-press.prevent="350"
            @long-press-start="onCommentLongTap()"
            @click="onCommentTap();"
          >
            <span
              v-if="votePrefix"
              class="common-text vote-prefix"
            >{{ votePrefix }}</span>
            <span class="common-text">{{ commentData.content }}</span>
          </div>
        </div>
        <div
          v-if="commentData.images && commentData.images.length > 0"
          v-long-press.prevent="350"
          @long-press-start="onCommentLongTap()"
          class="comment-image"
        >
          <div
            :style="{...calculateImageStyle({width: commentData.images[0].width,
                                             height: commentData.images[0].height})}"
            class="comment-image-item"
          >
            <div
              :style="{
                backgroundImage: `url(${$tgcimg(commentData.images[0].url)})`,
              }"
              @click.stop="$checkAppDeco(showImageViewer, commentData.images)"
              class="comment-image-item-main"
            />
          </div>
        </div>
      </div>
      <div class="com-comment-item-info-like" />
      <div
        v-if="type==='default' && Number(commentData.subCommentAmount)> 0"
        class="comment-reply"
      >
        <div
          v-for="(subComment,subIndex) in commentData.subCommentList"
          :key="subComment.id"
          class="row"
        >
          <div
            :style="{backgroundImage:` url(${ subComment.avatar})`}"
            @click="onAvatarClickHandler(subIndex)"
            class="row-avatar"
          />
          <div class="row-sub-comment">
            <div class="author">
              <div class="text-dinner">
                <span
                  @click="onAvatarClickHandler(subIndex)"
                  class="username ui-nowrap"
                >{{ subComment.nickname }}</span>
                <span
                  v-show="Number(subComment.attribute) === 2"
                  class="tag-author"
                >
                  <span class="tag-author-inner">作者</span>
                </span>
                <span
                  v-show="Number(subComment.attribute) === 3"
                  class="tag-official"
                />
                <span
                  v-show="Number(subComment.attribute) === 4"
                  class="tag-author"
                >
                  <span class="tag-author-inner">置顶</span>
                </span>
                <span class="time">{{ subComment.createdAt | getRelativeTime }}</span>
                <span class="ip-region">{{ subComment.ipRegionStr || '未知' }}</span>
              </div>
            </div>
            <div
              v-show="subComment.operatePopupStatus"
              class="tip"
            >
              <div class="tip-detail">
                <span
                  @click.stop="onCommentReply(subIndex);"
                  class="tip-detail-item"
                >回复</span>
                <span
                  v-if="subComment.content.trim()"
                  @click.stop="onCopyComment(subIndex)"
                  class="tip-detail-item"
                >复制</span>
                <input
                  ref="fakeCopyDetailRef"
                  :value="subComment.content"
                  class="tip-detail-hidden"
                  readonly
                >
                <span
                  v-if="userInfo && (Number(subComment.userId) === Number(userInfo.uid)) || hasDeletePerm"
                  @click.stop="onCommentDelete(subIndex)"
                  class="tip-detail-item"
                >删除</span>
                <span
                  v-if="!userInfo || (Number(subComment.userId) !== Number(userInfo.uid))"
                  @click.stop="onCommentAccuse(subIndex);"
                  class="tip-detail-item"
                >举报</span>
              </div>
              <div class="tip-triangle" />
            </div>
            <div
              v-show="subComment.operatePopupStatus"
              @touchstart="hideOperateDialog(subIndex)"
              class="shadow"
            />
            <div
              v-long-press.prevent="300"
              @long-press-start="onCommentLongTap(subIndex)"
              @click="onCommentTap(subIndex)"
              class="content"
            >
              <template v-if="Number(subComment.replyId)!==0">
                <span class="reply">回复</span>
                <span class="username ui-nowrap">{{ subComment.commentedNickname }}</span>
                <span
                  v-if="Number(subComment.commentedAccountType) > 0 && Number(subComment.commentedAccountType) < 3"
                  class="tag-official"
                />
                <span
                  v-else-if="creatorInfo && Number(creatorInfo.uid) === Number(subComment.commentatorId)"
                  class="tag-author"
                >
                  <span class="tag-author-inner">作者</span>
                </span>
                <span class="username">：</span>
              </template>
              <span>{{ subComment.content }}</span>
            </div>
            <div
              v-if="Array.isArray(subComment.images) && subComment.images.length > 0"
              v-long-press.prevent="300"
              @long-press-start="onCommentLongTap(subIndex)"
              class="comment-image"
            >
              <div
                :style="{...calculateImageStyle({width: subComment.images[0].width,
                                                 height: subComment.images[0].height})}"
                class="comment-image-item"
              >
                <div
                  :style="{
                    backgroundImage: `url(${$tgcimg(subComment.images[0].url)})`,
                  }"
                  @click.stop="$checkAppDeco(showImageViewer, subComment.images)"
                  class="comment-image-item-main"
                />
              </div>
            </div>
          </div>
          <div
            :class="{active: subComment.hasLike}"
            @click="$checkAuthDeco(onSubLikeHandler, subIndex);onLikeHandlerReport(subIndex)"
            class="row-like"
          >
            <Icon
              :active="!!(subComment.hasLike)"
              class="icon-like"
              size="m"
              name="applaud"
            />

            <div class="text">
              {{ subComment.like | translateNumToText }}
            </div>
          </div>
        </div>
        <div
          v-if="commentData.subCommentLoading"
          class="loading"
        >
          正在加载中...
        </div>
        <div
          v-else-if="commentData.subCommentError"
          @click="onSubCommentLoadMore(index)"
          class="more"
        >
          加载失败，点击重新加载
        </div>
        <div
          v-else-if="commentData.subCommentList.length < commentData.subCommentAmount"
          @click="onSubCommentLoadMore(index)"
          class="more"
        >
          <span v-if="!subCommentPageContext">
            展开{{ commentData.subCommentAmount - commentData.subCommentList.length }}条评论
          </span>
          <span v-else>展开更多回复</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Avatar from '@/ui/avatar/index.vue';
import LabelAuthorRank from '@/ui/label/author-rank.vue';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import OnlineStatus from '@/ui/label/online-status.vue';
import BadgeIcon from '@/ui/label/badge-icon.vue';
import Icon from '@/ui/icon';
import ApplaudAni from '@/ui/applaud-ani/index.vue';
import commentApi from '@/api/comment';
import jsbridge, { sendGlobalNotification } from '@/jsbridge';
import LongPress from '@/plugin/directive/longPress';
import GroupOwner from '@/ui/label/group-owner.vue';
import { getGroupOwnerType } from '@/business/post/post';
import { PERM_TAG } from '../post-manage/manage-dialog/store';

export default {
  name: 'ComCommentItem',
  directives: {
    'long-press': LongPress,
  },
  components: {
    BadgeIcon,
    Avatar,
    LabelAuthorRank,
    PrestigeIcon,
    Icon,
    OnlineStatus,
    ApplaudAni,
    GroupOwner,
  },
  inject: ['reportData'],
  props: {
    // default 显示所有信息 sub 子评论
    type: {
      type: String,
      default: 'default',
    },
    postInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    creatorInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    commentData: {
      type: Object,
      default: () => ({}),
    },
    commentFold: {
      type: Number,
      default: 2,
    },
    borderBottom: {
      type: [String, Boolean],
      default: true,
    },
    operateComment: {
      type: Boolean,
      default: true,
    },
    operateFeedback: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0, // 在列表中的顺序，从0开始
    },
    showOnlineStatus: {
      type: Boolean,
      default: true,
    },
    groupInfo: {
      type: Object,
      default: () => ({}),
    },
    subCommentPageContext: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      // 点赞请求后台中
      feedbackDialogStatus: false,
      operateLikeLoading: false,
      showApplaudAniTime: 0,
      showApplaudAniSubTime: 0,
      childHighLight: false,
      highLight: false,
      ifDelOrReport: false,
      copyDetail: '',
      childCommentNumber: 0,
      // 评论点击回复
      subCommentAmountReply: this.commentData.subCommentAmount,
      // 一级评论操作栏开关
      operatePopupStatus: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
      perms: state => state.manager?.perms,
    }),
    votePrefix() {
      return this.commentData?.votePrefix || '';
    },
    creatorUserInfo() {
      return (this.commentData && this.commentData.user) || {};
    },
    hasDeletePerm() {
      return this.perms && this.perms[PERM_TAG.delete];
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    this.reportExposure();
    document.oncontextmenu = function () {
      window.event.returnValue = false;
    };
  },
  methods: {
    getGroupOwnerType,
    // 计算图片宽高
    calculateImageStyle({ width, height }) {
      const imgData = {
        width: Number(width),
        height: Number(height),
      };
      if (!width || !height) {
        return {
          width: '210px',
          height: '210px',
        };
      }
      const w = imgData.width;
      const h = imgData.height;
      let fw = 0;
      let fh = 0;
      // debugger;
      if (imgData.width > 210) {
        // 当宽大于高时
        if (imgData.width > imgData.height) {
          fw = 210;
          fh = h / w * 210;
          if (fh < 118) {
            fh = 118;
          }
          // 当宽小于高时
        } else if (imgData.width < imgData.height) {
          fh = 210;
          fw = w / h * 210;
          if (fw < 118) {
            fw = 118;
          }
        } else if (imgData.width === imgData.height) {
          fh = 210;
          fw = 210;
        }
      } else if (imgData.width < 210) {
        if (imgData.width > 118) {
          if (imgData.width <= imgData.height) {
            if (imgData.height < 210) {
              fh = h;
              fw = w;
            } else if (imgData.height > 210) {
              fh = 210;
              fw = w / h * 210;
              if (fw < 118) {
                fw = 118;
              }
            }
          }
        } else if (imgData.width < 118) {
          if (imgData.width === imgData.height) {
            fh = 118;
            fw = 118;
          } else if (imgData.width < imgData.height) {
            if (imgData.height > 118) {
              fw = 118;
              fh = h / w * 118;
              if (fh > 210) {
                fh = 210;
              }
            } else if (imgData.height < 118) {
              fw = 118;
              fh = h / w * 118;
              if (fh > 210) {
                fh = 210;
              }
            }
          } else if (imgData.width > imgData.height) {
            fh = 118;
            fw = w / h * 118;
            if (fw > 210) {
              fw = 210;
            }
          }
        }
      }
      // console.log({
      //   width: fw,
      //   height: fh,
      // });
      return {
        width: `${fw}px`,
        height: `${fh}px`,
      };
    },
    /**
     * 更多
     * */
    onSubCommentLoadMore() {
      this.$emit('subCommentLoadMore');
    },
    // 评论区长按
    onCommentLongTap(subIndex) {
      if (this.reportData) {
        this.reportData({
          pageid: 1501,
          moduleid: 15, // 回复
          eventtype: 5,
          extendid: 1,
        }, '所有内容详情页长按评论呼起操作栏');
      }
      if (subIndex >= 0) {
        this.showOperateDialog(subIndex);
      } else {
        this.showOperateDialog();
      }
    },
    // 评论点击
    onCommentTap(subIndex) {
      if (subIndex >= 0) {
        this.$checkAuthDeco(() => {
          this.onCommentChildTextClickHandler(subIndex);
        });
        this.onCommentTextClickHandlerReport();
        this.hideOperateDialog(subIndex);
      } else {
        this.$checkAuthDeco(() => {
          this.onCommentTextClickHandler();
        });
        this.onCommentTextClickHandlerReport();
        this.hideOperateDialog();
      }
    },
    // 展示操作气泡
    showOperateDialog(subIndex) {
      if (subIndex >= 0) {
        this.$emit('changeSubCommentOperate', {
          index: this.index,
          subIndex,
          type: 'show',
        });
      } else {
        this.operatePopupStatus = true;
      }
    },
    // 隐藏操作气泡
    hideOperateDialog(subIndex) {
      if (subIndex >= 0) {
        this.$emit('changeSubCommentOperate', {
          index: this.index,
          subIndex,
          type: 'hide',
        });
      } else {
        this.operatePopupStatus = false;
      }
    },
    // 回复评论点击
    onCommentReply(subIndex) {
      if (this.reportData) {
        this.reportData({
          pageid: 1501,
          moduleid: 15, // 回复
          eventtype: 3,
          extendid: 5,
          ext1: 1,
        }, '所有内容详情页评论操作栏点击操作');
      }
      if (subIndex >= 0) {
        this.$checkAuthDeco(() => {
          this.onCommentChildTextClickHandler(subIndex);
        });
        this.onCommentTextClickHandlerReport(subIndex);
        this.hideOperateDialog(subIndex);
      } else {
        this.$checkAuthDeco(() => {
          this.onCommentTextClickHandler();
        });
        this.onCommentTextClickHandlerReport();
        this.hideOperateDialog();
      }
    },
    // 删除评论点击
    onCommentDelete(subIndex) {
      if (this.reportData) {
        this.reportData({
          pageid: 1501,
          moduleid: 15, // 回复
          eventtype: 3,
          extendid: 5,
          ext1: 3,
        }, '所有内容详情页评论操作栏点击操作');
      }
      if (subIndex >= 0) {
        this.$emit('deleteSubComment', {
          index: this.index,
          subIndex,
          commentData: this.commentData.subCommentList[subIndex],
          hasDeletePerm: this.hasDeletePerm,
        });
        this.hideOperateDialog(subIndex);
      } else {
        this.$emit('deleteComment', {
          index: this.index,
          commentData: this.commentData,
          hasDeletePerm: this.hasDeletePerm,
        });
        this.hideOperateDialog();
      }
    },
    // 举报评论点击
    onCommentAccuse(subIndex) {
      if (this.reportData) {
        this.reportData({
          pageid: 1501,
          moduleid: 15, // 回复
          eventtype: 3,
          extendid: 5,
          ext1: 4,
        }, '所有内容详情页评论操作栏点击操作');
        if (subIndex >= 0) {
          this.reportData({
            moduleid: 9,
            eventtype: 3,
            extendid: 2,
            indexid: subIndex + 1,
          }, '评论详情页评论负反馈点击');
        } else {
          this.reportData({
            moduleid: 9,
            eventtype: 3,
            extendid: 1,
            indexid: this.index + 1,
          }, '内容详情页评论负反馈点击');
        }
      }
      if (subIndex >= 0) {
        this.$checkAuthDeco(() => {
          this.$emit('accuseComment', this.commentData.subCommentList[subIndex]);
        });
        this.hideOperateDialog(subIndex);
      } else {
        this.$checkAuthDeco(() => {
          this.$emit('accuseComment', this.commentData);
        });
        this.hideOperateDialog();
      }
    },
    // 复制评论点击
    onCopyComment(subIndex) {
      if (this.reportData) {
        this.reportData({
          pageid: 1501,
          moduleid: 15, // 回复
          eventtype: 3,
          extendid: 5,
          ext1: 2,
        }, '所有内容详情页评论操作栏点击操作');
      }
      if (subIndex >= 0) {
        // const selection = window.getSelection();
        // const range = document.createRange();
        // range.selectNodeContents(this.$refs.fakeCopyDetailRef[subIndex]);
        // selection.removeAllRanges();
        // selection.addRange(range);
        const element = this.$refs.fakeCopyDetailRef[subIndex];
        element.select();
        element.setSelectionRange(0, element.value.length);
        // const text = element.value;
        // console.log(text);
        try {
          document.execCommand('copy');
          this.$toast('复制成功！');
        } catch (err) {
          this.$toast('复制失败');
        }
        this.hideOperateDialog(subIndex);
      } else {
        // const selection = window.getSelection();
        // const range = document.createRange();
        // range.selectNodeContents(this.$refs.fakeCopyCommentDetailRef);
        // selection.removeAllRanges();
        // selection.addRange(range);
        // console.log(this.$refs.fakeCopyCommentDetailRef.select());
        const element = this.$refs.fakeCopyCommentDetailRef;
        element.select();
        element.setSelectionRange(0, element.value.length);
        // const text = element.value;
        // console.log(text);
        try {
          document.execCommand('copy');
          this.$toast('复制成功！');
        } catch (err) {
          this.$toast('复制失败');
        }
        // document.execCommand('copy', false, this.$refs.fakeCopyCommentDetailRef.select());
        // this.$toast('复制成功！');
        this.hideOperateDialog();
      }
    },
    // 评论行为
    onCommentAction({ like, commentData } = {}) {
      return commentApi.updateCommentById({
        commentId: commentData.id,
        updateBitSet: 4,
        data: {
          userId: this.userInfo.uid,
          rootId: commentData.rootId,
          parentId: commentData.parentId,
          groupId: commentData.groupId,
          type: commentData.type,
          like,
        },
      });
    },
    // 一级评论点赞点击
    onLikeHandler() {
      if (this.operateLikeLoading) return false;
      if (this.commentData.hasLike) {
        this.operateLikeLoading = true;
        this.onCommentAction({
          like: 2,
          commentData: this.commentData,
        })
          .then(() => {
            this.operateLikeLoading = false;
            this.$emit('updateCommentItem', {
              parentId: this.commentData.parentId,
              commentId: this.commentData.id,
              data: {
                hasLike: false,
                like: this.commentData.like - 1,
              },
            });
            this.$emit('sendNotice', {
              operate: 'commentAction',
              data: 'unlike',
              commentData: this.commentData,
            });
          }, () => {
            // this.$toast('操作失败');
          })
          .finally(() => {
            this.operateLikeLoading = false;
          });
      } else {
        this.showApplaudAniTime = Date.now();
        this.operateLikeLoading = true;
        this.onCommentAction({
          like: 1,
          commentData: this.commentData,
        })
          .then(() => {
            this.operateLikeLoading = false;
            this.$emit('updateCommentItem', {
              parentId: this.commentData.parentId,
              commentId: this.commentData.id,
              data: {
                hasLike: true,
                like: this.commentData.like + 1,
              },
            });
            this.$emit('sendNotice', {
              operate: 'commentAction',
              data: 'like',
              commentData: this.commentData,
            });
            if (Date.now() - this.showApplaudAniTime >= 500) {
              // 动画已结束，直接隐藏动画
              this.showApplaudAniTime = 0;
            } else {
              setTimeout(() => {
                // 动画未结束，等播放完再隐藏动画
                this.showApplaudAniTime = 0;
              }, Date.now() - this.showApplaudAniTime);
            }
          }, () => {
            // this.$toast('操作失败');
            this.showApplaudAniTime = 0;
          })
          .finally(() => {
            this.operateLikeLoading = false;
          });
      }
      return true;
    },
    // 二级评论点赞
    onSubLikeHandler(index) {
      if (this.operateLikeLoading) return;
      if (this.commentData.subCommentList[index].hasLike) {
        this.operateLikeLoading = true;
        this.onCommentAction({
          like: 2,
          commentData: this.commentData.subCommentList[index],
        })
          .then(() => {
            // this.$emit('updateCommentList', {
            //   parentId: this.commentData.subCommentList[index].parentId,
            //   commentId: this.commentData.subCommentList[index].id,
            //   data: {
            //     hasLike: false,
            //     like: this.commentData.subCommentList[index].like - 1,
            //   },
            // });
            this.$emit('updateSubCommentItem', {
              parentId: this.commentData.subCommentList[index].parentId,
              commentId: this.commentData.subCommentList[index].id,
              data: {
                // 双层data兼容过去有detail逻辑
                data: {
                  hasLike: false,
                  like: this.commentData.subCommentList[index].like - 1,
                },
              },
            });
            this.$emit('sendNotice', {
              operate: 'commentAction',
              data: 'unlike',
              commentData: this.commentData.subCommentList[index],
            });
          })
          .finally(() => {
            this.operateLikeLoading = false;
          });
      } else {
        this.showApplaudAniSubTime = Date.now();
        this.operateLikeLoading = true;
        this.onCommentAction({
          like: 1,
          commentData: this.commentData.subCommentList[index],
        })
          .then(() => {
            this.operateLikeLoading = false;
            // this.$emit('updateCommentList', {
            //   parentId: this.commentData.subCommentList[index].parentId,
            //   commentId: this.commentData.subCommentList[index].id,
            //   data: {
            //     hasLike: true,
            //     like: this.commentData.subCommentList[index].like + 1,
            //   },
            // });
            this.$emit('updateSubCommentItem', {
              parentId: this.commentData.subCommentList[index].parentId,
              commentId: this.commentData.subCommentList[index].id,
              data: {
                // 双层data兼容过去有detail弹窗
                data: {
                  hasLike: true,
                  like: this.commentData.subCommentList[index].like + 1,
                },
              },
            });
            this.$emit('sendNotice', {
              operate: 'commentAction',
              data: 'like',
              commentData: this.commentData.subCommentList[index],
            });
            if (Date.now() - this.showApplaudAniSubTime >= 500) {
              // 动画已结束，直接隐藏动画
              this.showApplaudAniSubTime = 0;
            } else {
              setTimeout(() => {
                // 动画未结束，等播放完再隐藏动画
                this.showApplaudAniSubTime = 0;
              }, Date.now() - this.showApplaudAniSubTime);
            }
          }, () => {
            this.showApplaudAniSubTime = 0;
          })
          .finally(() => {
            this.operateLikeLoading = false;
          });
      }
    },
    // 点赞上报
    onLikeHandlerReport(subIndex) {
      if (this.reportData) {
        if (subIndex >= 0) {
          this.reportData({
            moduleid: 14,
            eventtype: 3,
            extendid: 4,
            indexid: subIndex + 1,
          }, '评论详情页给评论鼓掌');
        } else {
          this.reportData({
            moduleid: 14,
            eventtype: 3,
            extendid: 2,
            ext1: 1,
            ext2: 1,
            indexid: this.index + 1,
          }, '内容详情页一级评论鼓掌');
        }
      }
    },
    // 评论文字点击
    onCommentTextClickHandler() {
      this.$emit('postComment', this.commentData);
      return true;
    },
    // 子评论文字点击
    onCommentChildTextClickHandler(subIndex) {
      this.$emit('postSubComment', this.commentData.subCommentList[subIndex]);
      return true;
    },
    // 评论点击上报
    onCommentTextClickHandlerReport(subIndex) {
      if (this.reportData) {
        if (subIndex >= 0) {
          this.reportData({
            moduleid: 16,
            eventtype: 3,
            extendid: 2,
            indexid: subIndex + 1,
          }, '评论详情页回复内容点击');
        } else {
          this.reportData({
            moduleid: 16,
            eventtype: 3,
            extendid: 1,
            indexid: this.index + 1,
          }, '详情页一级评论正文点击回复');
        }
      }
    },
    // 评论头像点击
    onAvatarClickHandler(subIndex) {
      if (subIndex >= 0) {
        this.$checkAuthDeco(() => {
          this.$linkToPersonal(this.commentData.subCommentList[subIndex].userId);
        });
      } else {
        this.$checkAuthDeco(() => {
          this.$linkToPersonal(this.commentData.userId);
        });
      }
      this.onAvatarClickReport(subIndex);
    },
    // 评论头像点击上报
    onAvatarClickReport(subIndex) {
      if (this.reportData) {
        if (subIndex >= 0) {
          this.reportData({
            moduleid: 4,
            eventtype: 3,
            extendid: 4,
            indexid: subIndex + 1,
          }, '评论详情页头像点击');
        } else {
          this.reportData({
            moduleid: 4,
            eventtype: 3,
            extendid: 2,
            indexid: this.index + 1,
          }, '详情页评论者头像点击');
        }
      }
    },
    // 图片查看器
    showImageViewer(images) {
      this.$logger.info('on showImageViewer', images);
      if (this.$compareVersion('1.3') > -1) {
        images.forEach((item) => {
          // 使用tgcimg协议
          // eslint-disable-next-line no-param-reassign
          item.url = this.$tgcimg(item.url);
        });
        jsbridge.core.call('ui/image_preview', {
          index: 0, images,
        });
        if (this.reportData) {
          this.reportData({
            pageid: 1501,
            moduleid: 15,
            eventtype: 3,
            extendid: 2,
          }, '所有内容详情页评论图片点击');
        }
      } else {
        this.$toast('请升级至最新版本查看图片');
      }
    },
    // 上报图片曝光
    reportExposure() {
      if (this.commentData.images && this.commentData.images.length > 0) {
        if (this.reportData) {
          this.reportData({
            pageid: 1501,
            moduleid: 15,
            eventtype: 2,
            extendid: 1,
          }, '所有内容详情页评论图片曝光');
        }
      }
    },
    // 通知全局事件
    sendNotice(operate, data) {
      sendGlobalNotification('g_updateComment', {
        operate,
        root_id: this.commentData.rootId,
        parent_id: this.commentData.parentId,
        comment_id: this.commentData.id,
        data,
        type: this.commentData.type,
      });
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
